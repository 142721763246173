$lightgray: rgb(218, 216, 214);
$gray: rgb(227, 227, 227);
$darkgray: rgb(51, 51, 51);
$mediumgray: #dad8d6;
$white: #fff;
$border_style: 1px solid rgb(184, 192, 199);
$quote_bg: #e3e3e3;
$post_bg: #eff0f1;

$primary: $darkgray;

$chat-item-width: 70%;

@import "~bootstrap/scss/bootstrap";

@mixin black-link {
    font-size: 11px;
    text-decoration: underline !important;
    font-weight: 700;
    color: #000 !important;
}

* {
    font-size: 13px;
}

body {
    background-color: $mediumgray;
    color: $darkgray;
    font-family: Verdana,
        geneva,
        lucida,
        "lucida grande",
        arial,
        helvetica,
        sans-serif;
    padding-top: 80px;

    @media(min-width: 768px) {
        padding-top: 50px;
    }
}

#root {
    overflow-x: hidden;
}

.bordered {
    border: $border_style;
    border-radius: 0px;
    padding: 3px;
}

.text-regular {
    font-size: 13px;
}

input {
    border-radius: 0px;

    &[type="text"] {
        border-radius: 0px;
    }
}

textarea {
    border-radius: 0px !important;
}

/* Toast */
.Toastify__toast {
    font-weight: 600;
    border: $border-style;
    background: #fff;
    border-radius: 0;
    box-shadow: none;
    line-height: 1em;
}

.profile-letter {
    background: $quote_bg;
    color: $black;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 13px;

    &:hover {
        background: $mediumgray;
    }
}

.tab-pane {
    padding: 10px;
    background: $white;
}