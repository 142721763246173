.site-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #111;
    z-index: 10000;
}

.nav-menu-wrapper {
    /* Mobile styles */
    display: none;
    position: fixed;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: #111;
    height: 100%;
    width: 100%;

    &.mobile-menu-open {
        display: block;
    }

    /* Desktop styles */
    @media(min-width: 768px) {
        display: block;
        top: 0;
        bottom: auto;
        left: auto;
        right: auto;
        position: relative;
        height: auto;
        width: auto;
        background: none;
    }
}

.mobile-menu-controls {
    display: flex;
    justify-content: space-between;

    @media(min-width: 768px) {
        display: none;
    }

    .logo {
        display: inline-flex;
        align-items: center;

        img {
            height: 40px;
            margin-right: 10px;
        }

        a {
            color: $white !important;
            display: inline-flex;
            align-items: center;
            text-decoration: none !important;
            font-weight: 600;
        }

        p {
            margin-bottom: 0;
        }
    }

    .mobile-menu-button {
        display: inline-flex;
        padding: 10px;
        align-items: center;
        justify-content: center;
        background: transparent;
        appearance: none;
        border: none;

        svg {
            height: 40px;
        }

        line {
            stroke: $white;
        }
    }
}

.nav-menu {
    display: block;
    list-style: none;
    padding: 20px;
    text-align: right;

    .logo {
        display: none;
    }

    li {
        display: block;
        width: 100%;
        margin-bottom: 20px;

        a {
            font-size: 14px;
            color: #fff !important;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .logo {
        a {
            display: inline-flex;
            align-items: center;
            font-weight: 600;

            img {
                width: 20px;
                height: auto;
                margin-right: 10px;
            }

            p {
                color: #fff;
                margin: 0;
            }
        }

        @media(min-width: 768px) {
            display: inline-block;

            a {
                margin-right: 50px;
            }
        }
    }

    @media(min-width: 768px) {
        text-align: left;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;

        li {
            width: auto;
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 0;

            a {
                font-size: 12px;
            }
        }
    }

}