/* Custom thread selector */
.thread-selector-wrapper {
    position: relative;
    background: $white;
    border: $border_style;
    z-index: 5;
    .thread-selector-item {
        padding: 5px;
        display: block;
        text-decoration: none;
        &:hover {
            background: $post_bg;
        }
    }
    .thread-selector-forum-headline {
        padding: 2px 5px;
        background: $lightgray;
        font-weight: 600;
        margin: 0;
        font-size: 12px;
    }
    .thread-selector-dropdown {
        position: absolute;
        top: 100%;
        background: $white;
        left: 0;
        right: 0;
        width: 100%;
        border: $border_style;
        height: auto;
        max-height: 40vh;
        overflow-y: scroll;
        .thread-selector-item {
            &:nth-child(odd) {
                background: $post_bg;
            }
            &:hover {
                background: $lightgray;
            }
        }
    }
}

.thread-selector {
    flex: 0 1 auto;
    margin: 0 10px;
}

.thread-selector-select {
    appearance: none;
    -webkit-appearance: none;
    color: $black !important;
    height: 3em;
    width: 100%;
    padding: 5px;
    border: $border_style;
    border-radius: 0px;
    background: $white;
    font-size: 13px;

    @media(min-width: 768px) {
        margin: 0;
    }
}


.chat-input-form {
    //position: absolute;
    //bottom: 0;
    flex: 0 1 auto;
    background: $white;
    width: 100%;
    padding: 5px 10px;
}


.chat-container {
    margin-top: 1rem;
    //margin-bottom: 1rem;
    background: rgb(249, 249, 249);
    padding: 10px;
    //padding-bottom: 50px;
    //height: 70vh;
    height: 100%;
    overflow-y: scroll;

    .react-loading-skeleton {
        width: 100%;
    }
}

.chat-message-wrapper.user {
    text-align: right;
}

.chat-message {
    display: inline-block;
    border: 1px solid $lightgray;
    background-color: $white;
    font-size: 13px;
    padding: 5px 10px;
    margin-bottom: 1rem;
    width: auto;
    max-width: 80%;
    word-wrap: break-word;
    text-align: left;
    &.chat-message-skeleton {
        width: 80%;
    }

    h2 {
        font-size: 16px;
        margin: 0;
    }

    h3 {
        font-size: 14px;
        margin: 0;
    }

    ul {
        padding-left: 1em;

        li {
            margin-bottom: 0.75em;
        }
    }

    @media(min-width: 768px) {
        max-width: $chat-item-width;
    }

    *:last-child {
        margin-bottom: 0;
    }

    &.user {
        margin-right: 0;
        margin-left: auto;
    }

    &.model {
        margin-left: 0;
        margin-right: auto;
        background: $post_bg;
    }
}

.btn-svg {
    svg {
        height: 16px;
        width: auto;

        path {
            fill: $lightgray !important;
        }
    }

    &.btn-trash {
        svg {
            path {
                fill: red !important;
            }
        }

        &:hover {
            svg {
                path {
                    fill: $white !important;
                }
            }
        }
    }
}

.outer-chat-container {
    margin: 0 auto;

    @include media-breakpoint-up(md) {
        max-width: 80%;
    }

    @include media-breakpoint-up(lg) {
        max-width: 980px;
    }
}

.inner-chat-container {
    //padding: 0px 10px;
    display: flex;
    //flex-wrap: wrap;
    height: calc(100vh - 80px);
    height: calc(100dvh - 80px);
    flex-direction: column;
    overflow: hidden;
    
    @media(min-width: 768px) {
        padding: 0px 15px;
    }
}

.chat-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: scroll;
    position: relative;
    .thread-selector-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 3;
    }
}



.chat-suggestion {
    display: inline-block;
    background: $lightgray;
    color: $black;
    border: $border-style;
    padding: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
    font-size: 12px;
    text-decoration: none !important;

    &:hover {
        background: $gray;
    }
}

.chat-message-post-wrapper {
    display: inline-block;
    font-size: 13px;
    margin-bottom: 1rem;
    width: auto;
    width: 80%;
    word-wrap: break-word;
    margin-left: 0;
    margin-right: auto;
    border: 1px solid rgb(218, 216, 214);

    @media(min-width: 768px) {
        width: $chat-item-width;
    }

    .chat-message-post-header {
        background: #ccc;
        font-size: 11px;
        padding: 5px;
        display: flex;
        justify-content: space-between;

        p {
            margin: 0;
        }

        .post-id-link {
            @include black-link;
        }
    }

    .chat-message-post-body {
        display: flex;
        flex-wrap: wrap;
    }

    .chat-message-post-user {
        width: 100%;
        background: #e3e3e3;
        padding: 5px;

        @media(min-width: 768px) {
            width: 140px;
        }

        .username {
            @include black-link;
        }
    }

    .chat-message-post-content {
        width: 100%;
        flex: 1;
        background: $post_bg;
        padding: 5px;

        //white-space: pre-line;
        @media(min-width: 768px) {
            width: calc(100% - 140px);
        }

        i.glyphicon {
            font-style: normal;
        }

        .post-bbcode-quote-wrapper {
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
        }

        .post-bbcode-quote {
            padding: 5px;
            border: 1px solid #a4a4a4;
            background: $quote_bg;
        }

        blockquote {
            margin-top: 10px;
            margin-left: 10px;
            margin-bottom: 10px;
            padding-left: 10px;
            border-left: 5px solid #ccc;
        }
    }
}