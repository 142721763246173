.fb-form {
    background: #fff;
    padding: 15px;

    &.disabled {

        input,
        textarea,
        button,
        .btn {
            pointer-events: none;
            opacity: 0.6;
            cursor: not-allowed;
        }
    }

    label {
        font-weight: 600;
        font-size: 16px;
    }
}